import api from '@/api';
import { handleError } from '../../utils/errors';

const actions = {

    async getUserData({ commit, dispatch }, jwt) { // no JWT now?
        try {
            const response = await api.post('user/data');
            if(response.data && response.data.user_data) {
                commit('SET_USER', response.data.user_data);
            }
            //   const response = await axios.post(process.env.VUE_APP_API_URL+'user/data',
            //         { auth: jwt }
            //   );
            //   if(response.data && response.data.user_data) {
            //         commit('SET_USER', response.data.user_data);
            //   }
            return response.data;
        } catch (err) {
            // Check if it's a 404 error specifically
            if (err.response && err.response.status === 404) {
                // Handle "User not found" case
                dispatch('logout');
                // commit('SET_USER', null); // Clear user data
                return { user_data: null, status: 'not_found' };
            }

            // For other errors, use the error handler
            const errorMessage = handleError(err, '[STORE/user/getUserData]');
            dispatch('showNotification', { 
                message: 'Unable to fetch user data',
                type: 'error' 
            }, { root: true });
            
            throw new Error(errorMessage);
        }
    },

    async getUserInfo({ commit }, wallet) { // used in loadSession - if no jwt
        // same as getUserData but using wallet address if there is no jwt
        // try {
        //     console.log('getUserInfo for wall='+ wallet);
        //     const response = await api.post('wall/info',{
        //         wall: wallet
        //     });
        //     if(response.data && response.data.user_data) {
        //         commit('SET_USER', response.data.user_data);
        //     }
        //     return response.data;
        // } catch (error) {
        //     console.error('Failed to get user data:', error);
        //     // throw error;
        // }
    },

};

export default actions;
import axios from 'axios';
import store from './store';

// Add this at the top of the file
let isRefreshing = false;
let refreshSubscribers = [];

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  transformRequest: [(data, headers) => {
    // multipart/form-data
    if (data instanceof FormData) {
      return data;
    }
    // For regular JSON requests, continue with existing behavior
    if (typeof data === 'string') return data;
    return JSON.stringify(data);
  }],
});

// List of protected endpoint prefixes
const protectedEndpoints = [
  'user/',
];

// Helper function to check if an endpoint is protected
const isProtectedEndpoint = (url) => {
    const result = protectedEndpoints.some(endpoint => 
        url.startsWith(endpoint.replace(/\/$/, '')) || url.startsWith(endpoint)
    );
    // const result=protectedEndpoints.some(endpoint => url.startsWith(endpoint));
    return result;
};

// request interceptor
api.interceptors.request.use(
  (config) => {
    // Handle FormData differently
    if (config.data instanceof FormData) {
      // Add pid to FormData
      config.data.append('pid', process.env.VUE_APP_API_PID);
      // Add session token if exists
      if (store.state.sessionToken) {
        config.data.append('session', store.state.sessionToken);
      }
    } else {
      // Regular JSON request - keep existing behavior
      config.data = {
        ...config.data,
        pid: process.env.VUE_APP_API_PID,
        session: store.state.sessionToken
      };
    }

    // Authorization header handling same for both methods
    if (isProtectedEndpoint(config.url)) {
      const jwt = store.state.jwt;
      if (jwt) {
        config.headers['Authorization'] = `Bearer ${jwt}`;
      } else {
        return Promise.reject(new Error('No JWT token available for protected route'));
      }
    }

    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (
        error.response && error.response.status === 401 
        && isProtectedEndpoint(originalRequest.url) 
        && store.state.jwt
        && !originalRequest._retry
      ) 
    {
      
      if (!isRefreshing) {
        isRefreshing = true;
        originalRequest._retry = true;

        try {
          console.log('API response interceptor - Attempting to refresh token');
          const refreshResult = await store.dispatch('doRefreshJWT');
          console.log('API response interceptor - refreshResult:', JSON.stringify(refreshResult));

          if (refreshResult?.result === 'success' && refreshResult.jwt) {
            console.log('API response interceptor - Token refresh successful');
            console.log('New JWT:', refreshResult.jwt);

            // Update the Authorization header for the retry request
            originalRequest.headers['Authorization'] = `Bearer ${refreshResult.jwt}`;
            
            // Update the default headers for future requests
            api.defaults.headers.common['Authorization'] = `Bearer ${refreshResult.jwt}`;
            
            console.log('New Authorization header:', originalRequest.headers['Authorization']);
            
            // Resolve all the pending requests
            refreshSubscribers.forEach(callback => callback(refreshResult.jwt));
            refreshSubscribers = [];

            return api(originalRequest);
          } else {
            console.error('Token refresh failed or JWT is missing:', refreshResult);
            throw new Error('Token refresh failed or JWT is missing');
          }
        } catch (refreshError) {
          console.error('API response interceptor - Error refreshing token:', refreshError);
          refreshSubscribers = [];
          await logoutUser();
          return Promise.reject(refreshError);
        } finally {
          isRefreshing = false;
        }
      } else {
        // If a refresh is already in progress, wait for it to complete
        return new Promise((resolve) => {
          refreshSubscribers.push((token) => {
            originalRequest.headers['Authorization'] = `Bearer ${token}`;
            resolve(api(originalRequest));
          });
        });
      }
    }
    return Promise.reject(error);
  }
);

// Function to handle logout
async function logoutUser() {
    try {
      console.log('logoutUser');
      await store.dispatch('logout');
      // Redirect to login page
      // router.push('/user');
    } catch (logoutError) {
      console.error('Error during logout:', logoutError);
    }
  }

export default api;

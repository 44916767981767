import store from '@/store';

// Global errors from main.js

export function globalErrorHandler(error, vm, info) {

    console.error('Uncaught error:', error);
    console.error('Vue instance:', vm);
    console.error('Error info:', info);

    let message = error.response?.data?.message || error.message || 'An unexpected error occurred';
    message = 'Error - ' + message+ ' - Please try again later';

    store.commit('SET_APP_ERROR', { code: 'Global', text: message });

    store.dispatch('showNotification', { message: message, type: 'error' });

}
  
// Local errors from Store

  /**
 * Handles errors consistently across the application
 * @param {Error} err - The error object
 * @param {string} context - The context where the error occurred (e.g., '[STORE/auth/authWalletSetInit]')
 * @returns {string} A formatted error message
 */

export function handleError(err, context) {
  let errorMessage = '';
  
  // For axios errors
  if (err.response?.data?.message) {
    errorMessage = err.response.data.message;
  }
  // For regular errors
  else if (err.message) {
    errorMessage = err.message;
  }
  // Fallback
  else {
    errorMessage = 'An unexpected error occurred';
  }
  
  console.error(`${context} Error:`, errorMessage, err);
  return errorMessage;
}

const state = {
    modals: {
        Auth: { 
            show: false, 
            type: null 
        },
        Wallet: {
            show: false,
            view: null,
            newWallet: false
        },
        AuthTelegram: {
            show: false,
            code: null
        },
        AuthPhantom: {
            show: false,
            code: null
        },
        AuthSolflare: {
            show: false,
            code: null
        },
        Access: {
            show: false,
        },
        ConnectWallet: {
            show: false,
        },
        ConnectTelegram: {
            show: false,
        },
        ConnectEmail: {
            show: false,
        },
    }
}

const mutations = {

    MODAL_AUTH_SHOW(state) {
        console.log('MODAL_AUTH_SHOW mutation called');
        state.modals.Auth.show = true;
    },
    MODAL_AUTH_HIDE(state) {
        console.log('MODAL_AUTH_HIDE mutation called');
        state.modals.Auth.show = false;
    },

    MODAL_AUTH_TELEGRAM_SHOW(state, payload) {
        // console.log('MODAL_AUTH_TELEGRAM_SHOW mutation called with:', payload);
        // if (!payload || !payload.code) {
        //     console.warn('No code provided to MODAL_AUTH_TELEGRAM_SHOW');
        //     return;
        // }
        state.modals.AuthTelegram.show = true;
        state.modals.AuthTelegram.code = payload.code;
        console.log('Updated state:', state.modals.AuthTelegram);
    },
    MODAL_AUTH_TELEGRAM_HIDE(state) {
        console.log('MODAL_AUTH_TELEGRAM_HIDE mutation called');
        state.modals.AuthTelegram.show = false;
        state.modals.AuthTelegram.code = null;
    },

    MODAL_AUTH_PHANTOM_SHOW(state, payload) {
        console.log('MODAL_AUTH_PHANTOM_SHOW mutation called with:', payload);
        state.modals.AuthPhantom.show = true;
        // state.modals.AuthPhantom.code = payload?.code || null;
    },
    MODAL_AUTH_PHANTOM_HIDE(state) {
        console.log('MODAL_AUTH_PHANTOM_HIDE mutation called');
        state.modals.AuthPhantom.show = false;
        // state.modals.AuthPhantom.code = null;
    },

    MODAL_AUTH_SOLFLARE_SHOW(state, payload) {
        console.log('MODAL_AUTH_SOLFLARE_SHOW mutation called with:', payload);
        state.modals.AuthSolflare.show = true;
        state.modals.AuthSolflare.code = payload?.code || null;
    },
    MODAL_AUTH_SOLFLARE_HIDE(state) {
        console.log('MODAL_AUTH_SOLFLARE_HIDE mutation called');
        state.modals.AuthSolflare.show = false;
        state.modals.AuthSolflare.code = null;
    },

    MODAL_WALLET_SHOW(state, payload) {
        console.log('MODAL_WALLET_SHOW mutation called with:', payload);
        state.modals.Wallet.show = true;
        state.modals.Wallet.view = payload?.view || 'home';
        state.modals.Wallet.newWallet = payload?.newWallet || false;
    },
    MODAL_WALLET_HIDE(state) {
        console.log('MODAL_WALLET_HIDE mutation called');
        state.modals.Wallet.show = false;
        state.modals.Wallet.view = null;
        state.modals.Wallet.newWallet = false;
    },

    MODAL_ACCESS_SHOW(state) {
        console.log('MODAL_ACCESS_SHOW mutation called');
        state.modals.Access.show = true;
    },
    MODAL_ACCESS_HIDE(state) {
        console.log('MODAL_ACCESS_HIDE mutation called');
        state.modals.Access.show = false;
    },

    MODAL_WALLET_RESET_NEW(state) { state.modals.Wallet.newWallet = false; },

    MODAL_CONNECT_WALLET_SHOW(state) { state.modals.ConnectWallet.show = true; },
    MODAL_CONNECT_WALLET_HIDE(state) { state.modals.ConnectWallet.show = false; },

    MODAL_CONNECT_TELEGRAM_SHOW(state) { state.modals.ConnectTelegram.show = true; },
    MODAL_CONNECT_TELEGRAM_HIDE(state) { state.modals.ConnectTelegram.show = false; },

    MODAL_CONNECT_EMAIL_SHOW(state) { state.modals.ConnectEmail.show = true; },
    MODAL_CONNECT_EMAIL_HIDE(state) { state.modals.ConnectEmail.show = false; },

}

const actions = {

    showAuthModal({ commit }) {
        console.log('showAuthModal action called');
        commit('MODAL_AUTH_SHOW');
    },
    hideAuthModal({ commit }) {
        console.log('hideAuthModal action called');
        commit('MODAL_AUTH_HIDE');
    },

    showWalletModal({ commit }, payload) {
        console.log('showWalletModal action called with:', payload);
        commit('MODAL_WALLET_SHOW', payload);
    },
    hideWalletModal({ commit }) {
        console.log('hideWalletModal action called');
        commit('MODAL_WALLET_HIDE');
    },

    showAccessModal({ commit }) {
        console.log('showAccessModal action called');
        commit('MODAL_ACCESS_SHOW');
    },
    hideAccessModal({ commit }) {
        console.log('hideAccessModal action called');
        commit('MODAL_ACCESS_HIDE');
    },

    resetWalletNew({ commit }) {
        console.log('resetWalletNew action called');
        commit('MODAL_WALLET_RESET_NEW');
    },

    showConnectWalletModal({ commit }) { commit('MODAL_CONNECT_WALLET_SHOW'); },
    hideConnectWalletModal({ commit }) { commit('MODAL_CONNECT_WALLET_HIDE'); },

    showConnectTelegramModal({ commit }) { commit('MODAL_CONNECT_TELEGRAM_SHOW'); },
    hideConnectTelegramModal({ commit }) { commit('MODAL_CONNECT_TELEGRAM_HIDE'); },

    showConnectEmailModal({ commit }) { commit('MODAL_CONNECT_EMAIL_SHOW'); },
    hideConnectEmailModal({ commit }) { commit('MODAL_CONNECT_EMAIL_HIDE'); },

    showAuthPhantomModal({ commit }, payload) { commit('MODAL_AUTH_PHANTOM_SHOW', payload); },
    hideAuthPhantomModal({ commit }) { commit('MODAL_AUTH_PHANTOM_HIDE'); },

    showAuthSolflareModal({ commit }, payload) { commit('MODAL_AUTH_SOLFLARE_SHOW', payload); },
    hideAuthSolflareModal({ commit }) { commit('MODAL_AUTH_SOLFLARE_HIDE'); },

}

const getters = {
    openAuth: state => state.modals.Auth.show,
    openAuthTelegram: state => state.modals.AuthTelegram.show,
    openAuthPhantom: state => state.modals.AuthPhantom.show,
    openAuthSolflare: state => state.modals.AuthSolflare.show,
    openAccess: state => state.modals.Access.show,
    openWallet: state => state.modals.Wallet.show,
    openWalletView: state => state.modals.Wallet.view,
    openWalletNewWallet: state => state.modals.Wallet.newWallet,
    openConnectWallet: state => state.modals.ConnectWallet.show,
    openConnectTelegram: state => state.modals.ConnectTelegram.show,
    openConnectEmail: state => state.modals.ConnectEmail.show,
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
import axios from 'axios';

const actions = {

    async initSession({ dispatch, commit, state }) { // Gets public data 

        try {

            console.log('[sess/initSession] Init app');
            const response = await axios.post(process.env.VUE_APP_API_URL+'sess/init', 
                {
                    pid: process.env.VUE_APP_API_PID
                }
            );
            if (response.data.status === 'success') {
                if (response.data.version === process.env.VUE_APP_API_VER) {
                    console.log('[sess/initSession] API version '+response.data.version+' OK');
                    commit('SET_APP_DATA',response.data.public_data);
                    // alert(JSON.stringify(response.data.data.public_data));
                    if(response.data.data.public_data.price) {
                        // alert(JSON.stringify('price = '+JSON.stringify(response.data.data.public_data.price)));
                        dispatch('ws/handlePriceMessage', response.data.data.public_data.price);
                    }
                } else {
                    throw new Error(`Expired version. Please reload the app - version ${response.data.version} is required (current version ${process.env.VUE_APP_API_VER}).`);
                }
            } else {
                const error = new Error(response.data.message || 'Failed to load session');
                error.response = response;
                throw error;
            }

            const sessionData = {
                language: navigator.language || navigator.userLanguage,
                user_agent: navigator.userAgent,
                utm_source: state.utmSource,
                utm_campaign: state.utmCampaign,
                utm_medium: state.utmMedium,
            };
            
            console.log('[sess/initSession] with data: '+JSON.stringify(sessionData));
            if (state.sessionToken) {
                console.log('[sess/initSession] 1 - Session token found - load session: '+state.sessionToken+' data: '+JSON.stringify(sessionData));
                dispatch('loadSession', { sessionToken: state.sessionToken, sessionData })
                .then(() => {
                    if(state.jwt) {
                        console.log('[sess] 2 - Session loaded - there is JWT - getUserData: '+state.jwt);
                        dispatch('getUserData', state.jwt);
                    } 
                    // else if(state.walletAddr) {
                    //     dispatch('getUserInfo', state.walletAddr);
                    // }
                })
                .catch(error => {
                    console.warn('[sess/initSession] Failed to load existing session:', error);
                    localStorage.removeItem('sessionToken');
                    console.log('[sess/initSession] - Error loading session ('+error+') - do createSession');
                    dispatch('createSession', sessionData);
                });
            } else {
                console.log('[sess/initSession] No session - createSession');
                dispatch('createSession', sessionData);
            }
            
        } catch (error) {
            console.error('Error loading session:', error);
            const errorMessage = error.response?.data?.message || error.message || 'Failed to load session';
            commit('SET_APP_ERROR', { code: 'Init', text: errorMessage });
            throw error;
        } finally {
            commit('SET_APP_LOAD', true);
        }

    },

    async loadSession({ commit }, { sessionToken, sessionData }) {
        try {
            console.log('Loading session: '+sessionToken+' wif data: '+JSON.stringify(sessionData)); 
            const response = await axios.post(process.env.VUE_APP_API_URL+'sess/load', 
                {
                    pid: process.env.VUE_APP_API_PID,
                    session: sessionToken,
                    data: sessionData,
                }
            );
            if (response.data.status === 'success') {
                console.log('[sess/loadSession] Session loaded: '+sessionToken);
                commit('SET_SESSION_TOKEN', sessionToken);
                return true;
            } else {
                const error = new Error(response.data.message || 'Failed to load session');
                error.response = response;
                throw error;
            }
        } catch (error) {
            console.error('Error loading session:', error);
            const errorMessage = error.response?.data?.message || error.message || 'Failed to load session';
            throw new Error(errorMessage);
        }
    },

    async createSession({ commit }, sessionData) {
        try {
            const response = await axios.post(process.env.VUE_APP_API_URL+'sess/create', 
                {
                    pid: process.env.VUE_APP_API_PID,
                    data: sessionData
                }
            );
            if (response.data.status === 'success') {
                console.log('Session created: '+response.data.sessionToken);
                commit('SET_SESSION_TOKEN', response.data.sessionToken);
                return true;
            } else {
                const error = new Error(response.data.message || 'Failed to create session');
                error.response = response;
                throw error;
            }
        } catch (error) {
            console.error('Error creating session:', error);
            const errorMessage = error.response?.data?.message || error.message || 'Failed to create session';
            throw new Error(errorMessage);
        }
    },

};

export default actions;